import * as React from "react";
import "antd/dist/antd.css";
import "../assets/styles.scss";
import Main from "../layout/layout";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col } from "antd";
import { Helmet } from "react-helmet";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SEO from "../layout/seo";
// markup
const About = ({ location }) => {
  const { t } = useTranslation();

  return (
    <main>
      <SEO title={t("About")}
        description={t("Description about")}></SEO>
      <Main location={location}>
        <div className="main-container">
          <div className="container-text">
            <Row>
              <Col xs={24} md={8} style={{textAlign:"center"}}>
                <StaticImage
                  className="photo-about"
                  quality={90}
                  src="../images/portrait.png"
                  alt="Portrait of Victor Chevalier"
                />
              </Col>

              <Col xs={24} md={16}>
                <div style={{ marginBottom: "2rem" }} className="title-page">
                  <Trans>About</Trans>
                  <div className="hr"></div>
                </div>
                <p className="description">
                  <Trans>Bio</Trans>
                </p>
              </Col>
            </Row>

        
            <Row>
              <Col xs={{order:2, span:24}} xl={{order:2, span:14}}>
              <div style={{ marginBottom: "2rem" }} className="title-page">
              <Trans>Events</Trans>
              <div className="hr"></div>
            </div>

                <ul className="list-about">
                  <li>
                    2012 – <Trans>Letspik</Trans>
                  </li>
                  <li>
                    {" "}
                    2013 – <Trans>M6</Trans>
                  </li>
                  <li>
                    {" "}
                    2014 – <Trans>Metrowild</Trans>
                  </li>
                  <li>
                    {" "}
                    2014 – <Trans>OrNorme</Trans>
                  </li>
                  <li>
                    {" "}
                    2017 – <Trans>Medecin</Trans>
                  </li>
                  <li>
                    {" "}
                    2018 – <Trans>Festival</Trans>
                  </li>
                  <li>
                    {" "}
                    2020 – <Trans>Poster</Trans>
                  </li>
                  <li>
                    {" "}
                    2021 – <Trans>Nocturnes</Trans>
                  </li>
                  <li>
                    {" "}
                    2021 – <Trans>Coulhété</Trans>
                  </li>
                  <li>
                    {" "}
                    2021 – <Trans>Coulhaut</Trans>
                  </li>
                  <li>
                    {" "}
                    2022 – <Trans>Casa</Trans>
                  </li>
                </ul>
              </Col>
              <Col className="vhalign" xs={{order:1, span:24}} xl={{order:2, span:10}}>

                  <StaticImage
                    className="photo-about"
                    quality={90}
                    src="../images/victorpainting2.jpg"
                    alt="Portrait of Victor Chevalier painting"
                  />

    
              </Col>
            </Row>

            <Row style={{ paddingTop: "2rem" }}>
              <Col xs={24} xl={10}>
                <Carousel autoPlay={true} showArrows={false} showStatus={false}>

                  <StaticImage
                    className="photo-about"
                    quality={90}
                    src="../images/amelie.jpg"
                    alt="Affiche du Fabuleux Destin d'Amélie Poulain détournée par temps de COVID"
                  />

                  <StaticImage
                    className="photo-about"
                    quality={90}
                    src="../images/deco.jpg"
                    alt="Victor Chevalier sur D&CO sur M6"
                  />
                </Carousel>
              </Col>
              <Col xs={24} xl={14}>
                <div style={{ marginBottom: "2rem" }} className="title-page">
                  <Trans>Press</Trans>
                  <div className="hr"></div>
                </div>

                <ul className="list-about">
                <li>
                    {" "}
                    2013 – <Trans>M6</Trans>
                  </li>
                  <li>
                    2014 – <Trans>OrNorme</Trans>
                  </li>

                  <li>
                    {" "}
                    2020 – CNEWS –{" "}
                    <a
                      href="https://www.cnews.fr/diaporamas/des-affiches-de-films-revues-et-corrigees-lheure-du-coronavirus-945362?"
                      target="_blank"
                    >
                      <Trans>CNEWS</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="" target="_blank"></a> 2020 – Huffington Post –{" "}
                    <a
                      href="https://www.huffingtonpost.fr/culture/article/coronavirus-des-affiches-de-films-revisitees-par-temps-d-epidemie_162473.html"
                      target="_blank"
                    >
                      <Trans>Huff</Trans>
                    </a>
                  </li>

                  <li>
                    {" "}
                    2021 – Nice Matin – <Trans>NMBiot</Trans>
                  </li>
                  <li>
                    {" "}
                    2021 – Nice Matin –{" "}
                    <a
                      href="https://www.nicematin.com/arts-plastiques/diffuser-un-message-cest-primordial-a-antibes-lart-urbain-gagne-la-rue-724032"
                      target="_blank"
                    >
                      <Trans>NMAntibes</Trans>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Main>
    </main>
  );
};
export const queryAbout = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default About;
